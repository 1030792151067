import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ disclaimer }) => (
  <div className="footer">
    <span className="footer-text">{disclaimer}</span>
  </div>
);

Footer.propTypes = {
  disclaimer: PropTypes.string,
};

Footer.defaultProps = {
  disclaimer: 'We may have an affiliate relationship with some of the stores and the site may get compensated if the consumer chooses to buy the product using the links provided on the site. However, it won’t impact the price of the product for the consumer. All logos and trademarks are the property of the respective trademark owners. We are also a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites. Amazon, Amazon Prime, the Amazon logo and Amazon Prime logo are trademarks of Amazon.com, Inc. or its affiliates.',
};

export default Footer;
