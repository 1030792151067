import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'react-ga';

import { ImageLoader } from './Loaders';
import images from './resources';

class Card extends PureComponent {
  static propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    red_price: PropTypes.string,
    cta: PropTypes.string,
    likes: PropTypes.number,
    store: PropTypes.string,
  };

  static defaultProps = {
    image: images.unavailable,
    title: '',
    price: '',
    red_price: '',
    cta: '#',
    likes: 0,
    store: 'amazon',
  };

  state = {
    status: 'loading',
    likes: Math.floor(Math.random() * 100),
    isLiked: false,
  };

  componentDidMount() {
    const { likes } = this.props;
    if (likes) {
      this.setState({ likes });
    }
  }

  incrementLikes = () => this.setState(prevState => ({ likes: prevState.likes + 1, isLiked: true }));

  decrementLikes = () => this.setState(prevState => ({ likes: prevState.likes - 1, isLiked: false }));

  getButton = () => {
    const { store } = this.props;
    switch (store) {
      case 'misc':
        return (
          <div className="card-button">
            <p className="card-button-text">VIEW DEAL</p>
          </div>
        );

      default:
        return (
          <div className="card-wrapper">
            <div className="card-button">
              <p className="card-button-text">VIEW DEAL</p>
              <i className="fas fa-long-arrow-alt-right card-button-arrow"></i>
              <img className="card-button-logo" src={images[store]} alt={store} />
            </div>
          </div>
        );
    }
  };

  getImage = () => {
    const { status } = this.state;
    const { image, title } = this.props;
    switch (status) {
      case 'loading':
      case 'loaded':
        return (
          <div>
            {status === 'loaded' ? null: <ImageLoader />}
            <img
              className="card-image"
              style={{ display: status === 'loaded' ? 'block' : 'none' }}
              src={image}
              alt={title}
              onLoad={() => this.setState({ status: 'loaded' })}
              onError={() => this.setState({ status: 'failed' })}
            />
          </div>
        );

      case 'failed':
        return <img className="card-image" src={images.unavailable} alt="Unavailable" />;

      default:
        return null;
    }
  };

  render() {
    const { isLiked, likes } = this.state;
    const { title, price, red_price, cta } = this.props;
    return (
      <div className="card">
        <OutboundLink to={cta} target="_blank" eventLabel={title}>
          <div className="card-wrapper">
            {this.getImage()}
            <div className="card-title">
              <span>{title}</span>
            </div>
            <div className="card-price">
              <span className="card-price-slashed">{price}</span>
              <span className="card-price-real">{red_price}</span>
            </div>
          </div>
        </OutboundLink>
        <div className="card-likes">
          <span className="card-likes-text">{likes}</span>
          {isLiked ?
          <i className="fas fa-heart card-likes-icon" onClick={this.decrementLikes}></i> :
          <i className="far fa-heart card-likes-icon" onClick={this.incrementLikes}></i>}
        </div>
        <OutboundLink to={cta} target="_blank" eventLabel={title}>
          {this.getButton()}
        </OutboundLink>
      </div>
    );
  }
}

export default Card;
