import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
} from 'react-share';

import images from './resources';

const Share = ({ shareUrl, title }) => (
  <div className="share-container">
    <FacebookShareButton url={shareUrl} quote={title}>
      <FacebookIcon size={32} />
    </FacebookShareButton>
    <TwitterShareButton url={shareUrl} title={title}>
      <TwitterIcon size={32} />
    </TwitterShareButton>
    <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
      <WhatsappIcon size={32} />
    </WhatsappShareButton>
    <PinterestShareButton url={shareUrl} media={`${shareUrl}/${images.amazon}`}>
      <PinterestIcon size={32} />
    </PinterestShareButton>
  </div>
);

Share.propTypes = {
  shareUrl: PropTypes.string,
  title: PropTypes.string,
};

Share.defaultProps = {
  shareUrl: 'https://blackfriday-us-e3ed3.firebaseapp.com',
  title: 'Black Friday',
};

export default Share;
