import React from 'react';
import PropTypes from 'prop-types';
import escapeRegExp from 'escape-string-regexp';

import Card from './Card';
import ScrollToTop from './ScrollToTop';

const ListItems = ({ data, search, isLoading }) => {
  let displayedData = [];
  if (search) {
    const match = new RegExp(escapeRegExp(search), 'i');
    displayedData = data.filter(product =>
      match.test(product.title)
    );
  } else {
    displayedData = data;
  }
  return (
    <div className="listItems">
      <ScrollToTop />
      {!(isLoading || displayedData.length) ? <h2>No data found here!</h2> : null}
      {displayedData.map((card, index) => (
        <Card
          key={index}
          image={card.image}
          title={card.title}
          price={card.price}
          red_price={card.red_price}
          cta={card.cta}
          likes={card.likes}
          store={card.store}
        />
      ))}
    </div>
  );
};

ListItems.propTypes = {
  data: PropTypes.array,
  search: PropTypes.string,
};
  
ListItems.defaultProps = {
  data: [],
  search: '',
};

export default ListItems;
