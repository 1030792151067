import amazon from './amazon.png';
import target from './target.png';
import walmart from './walmart.png';
import bestbuy from './bestbuy.png';
import microsoft from './microsoft.png';
import att from './att.png';
import gamestop from './gamestop.png';
import samsclub from './samsclub.png';
import macys from './macys.png';
import amazon_big from './amazon_big.png';
import target_big from './target_big.png';
import walmart_big from './walmart_big.png';
import bestbuy_big from './bestbuy_big.png';
import microsoft_big from './microsoft_big.png';
import att_big from './att_big.png';
import gamestop_big from './gamestop_big.png';
import samsclub_big from './samsclub_big.png';
import macys_big from './macys_big.png';
import misc_big from './misc_big.png';
import unavailable from './unavailable.jpg';

const images = {
  amazon,
  target,
  walmart,
  bestbuy,
  microsoft,
  att,
  gamestop,
  samsclub,
  macys,
  amazon_big,
  target_big,
  walmart_big,
  bestbuy_big,
  microsoft_big,
  att_big,
  gamestop_big,
  samsclub_big,
  macys_big,
  misc_big,
  unavailable,
};

export default images;
