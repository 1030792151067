/* globals window */
/* globals document */

import React, { PureComponent } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import Navbar from './Navbar';
import ListItems from './ListItems';
import Stores from './Stores';
import Share from './Share';
import Mailchimp from './Mailchimp';
import Footer from './Footer';
import { PageLoader } from './Loaders';
import { getRoutes } from './Routes';
import { getData } from './Firebase';

import './App.scss';

const shareUrl = window.location.origin;

class App extends PureComponent {
  state = {
    search: '',
    products: [],
    routes: [],
    stores: [],
    title: 'BLACK FRIDAY',
    disclaimer: '',
    searchText: '',
    isLoading: true,
  };

  async componentDidMount() {
    ReactGA.initialize('UA-115995879-3');
    const { title, products, categories, stores, disclaimer, searchText } = await getData();
    const routes = getRoutes(products, categories, stores);
    this.setState(prevState => ({
      routes,
      products,
      stores,
      title: title || prevState.title,
      disclaimer,
      searchText,
      isLoading: false,
    }));
  }

  searchHandler = (search) => this.setState({ search });

  render() {
    const { search, products, routes, stores, title, disclaimer, searchText, isLoading } = this.state;
    return (
      <BrowserRouter>
        <div className="container">
          <Navbar routes={routes} searchHandler={this.searchHandler} title={title} searchText={searchText} />
          <Share shareUrl={shareUrl} title={title} />
          <Mailchimp />
          {isLoading ? <PageLoader /> : null}
          <Route path="/" exact render={() => <ListItems data={products} search={search} isLoading={isLoading} />} />
          {routes.map(route => (
            <Route {...route} render={() => <ListItems data={route.data} search={search} isLoading={isLoading} />} />
            ))}
          <Route path="/stores" render={() => <Stores search={search} stores={stores} />} />
          <Footer disclaimer={disclaimer} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
