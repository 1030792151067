import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import escapeRegExp from 'escape-string-regexp';

import images from './resources';
import ScrollToTop from './ScrollToTop';

const Stores = ({ search, stores }) => {
  let displayedStores = [];
  if (search) {
    const match = new RegExp(escapeRegExp(search), 'i');
    displayedStores = stores.filter(store =>
      match.test(store)
    );
  } else {
    displayedStores = stores;
  }
  return (
    <div className="listItems">
      <ScrollToTop />
      {displayedStores.map(store => (
        <Link key={store} to={`/store/${store}`}>
          <div className="store-wrapper">
            <img className="store-image" src={images[`${store}_big`]} alt={store} />
          </div>
        </Link>
      ))}
    </div>
  );
};

Stores.propTypes = {
  search: PropTypes.string,
  stores: PropTypes.array,
};

Stores.defaultProps = {
  search: '',
  stores: [],
};

export default Stores;
