import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

class Navbar extends Component {
  static propTypes = {
    routes: PropTypes.array,
    searchHandler: PropTypes.func.isRequired,
    title: PropTypes.string,
    searchText: PropTypes.string,
  };
  
  static defaultProps = {
    routes: [],
    title: 'BLACK FRIDAY',
    searchText: 'What are you looking for?',
  };

  state = {
    display: false,
  };

  render() {
    const { routes, searchHandler, title, searchText } = this.props;
    const { display } = this.state;
    return (
      <div className="navbar">
        <Link to="/"><h1 className="navbar-logo">{title}</h1></Link>
        <div className="navbar-right">
          <input
            className="navbar-search"
            type="text"
            placeholder={searchText}
            onChange={event => searchHandler(event.target.value || '')}
          />
          <i className="fas fa-search navbar-right-icon"></i>
        </div>
        <div className="navbar-items">
          {routes.length ? <NavLink className="navbar-links" activeClassName="navbar-links-active" to="/" exact>Home</NavLink> : null}
          <div className="navbar-items-big">
            {routes.map((route) => {
              if (route.type === 'store') {
                return null;
              }
              return (
                <NavLink className="navbar-links" activeClassName="navbar-links-active" key={route.key} to={route.path} exact={route.exact}>{route.key}</NavLink>
              );
            })}
          </div>
          <div className="navbar-items-small">
            <div className="navbar-links" onClick={() => this.setState(prevState => ({ display: !prevState.display }))}>
              <i className={`fas fa-${display ? 'times' : 'bars'}`}></i>
            </div>
          </div>
          {routes.length ? <NavLink className="navbar-links navbar-links-store" activeClassName="navbar-links-active" to="/stores">Stores</NavLink> : null}
        </div>
        {display ? (
          <div className="navbar-items-dropdown navbar-items">
            {routes.map((route, index) => {
              if (route.type === 'store' || index === 0) {
                return null;
              }
              return (
                <NavLink
                  className="navbar-links"
                  activeClassName="navbar-links-active"
                  key={route.key}
                  to={route.path}
                  exact={route.exact}
                  onClick={() => this.setState({ display: false })}
                >
                  {route.key}
                </NavLink>
              );
            })}
          </div>) :
          null}
      </div>
    );
  }
};

export default Navbar;
