import React, { PureComponent } from 'react';

const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const checkValidEmail = email => emailRegex.test(email);

class Mailchimp extends PureComponent {
  state = {
    email: '',
    isVisible: false,
    isValid: true,
    isSubmitted: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ isVisible: true }), 30000);
  }

  handleInput = event => this.setState({ email: event.target.value });

  handleClose = () => this.setState({ isVisible: false });

  handleSubmit = () => {
    const { email } = this.state;
    if(!checkValidEmail(email)) {
      this.setState({ isValid: false });
      return;
    }
    this.setState({ isValid: true, isSubmitted: true });
    setTimeout(() => this.setState({ isVisible: false }), 10000);
    fetch('https://website.us17.list-manage.com/subscribe/post?u=9b09920d0120ce1606477b615&amp;id=c9cc76f2e3', {
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'no-cors',
      method: 'POST',
      body: JSON.stringify({
        EMAIL: email,
      }),
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  render() {
    const { email, isVisible, isValid, isSubmitted } = this.state;
    if (!isVisible) {
      return null;
    }
    if (isSubmitted) {
      return (
        <div className="mailchimp">
          <div className="mailchimp-top">
            <span className="mailchimp-text mailchimp-text-thanks">Thanks for subscribing to our FREE exclusive deals newsletter. Keep an eye on your inbox. Happy Shopping <i className="far fa-grin-beam"></i></span>
            <i className="fas fa-times mailchimp-cross" onClick={this.handleClose}></i>
          </div>
        </div>
      );
    }
    return (
      <div className="mailchimp">
        <div className="mailchimp-top">
          <span className="mailchimp-text">Don't Want to Miss Any Black Friday Deal?</span>
          <i className="fas fa-times mailchimp-cross" onClick={this.handleClose}></i>
        </div>
        <div className="mailchimp-bottom">
          <input
            className="mailchimp-input"
            placeholder="Please enter your email"
            type="text" value={email}
            onChange={this.handleInput}
            onKeyPress={this.handleKeyPress}
          />
          <div className="mailchimp-button" onClick={this.handleSubmit}>
            <span className="mailchimp-button-text">Subscribe</span>
          </div>
        </div>
        {isValid ? null : <span className="mailchimp-error">The email you entered is invalid, please try again.</span>}
      </div>
    );
  }
};

export default Mailchimp;
