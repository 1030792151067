import React from 'react';

const PageLoader = () => (
  <div className="pageLoader">
    <i className="fas fa-circle-notch fa-7x fa-spin"></i>
  </div>
);

const ImageLoader = () => (
  <div className="imageLoader">
    <i className="fas fa-circle-notch fa-2x fa-spin"></i>
  </div>
);

export {
  PageLoader,
  ImageLoader,
};
