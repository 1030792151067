const getRoutes = (products, categories, stores) => {
  const data = {};
  const routes = [];
  categories.forEach((category) => {
    data[category] = [];
    routes.push({
      key: category,
      type: 'category',
      path: `/category/${category}`,
      data: data[category],
    });
  });
  stores.forEach((store) => {
    data[store] = [];
    routes.push({
      key: store,
      type: 'store',
      path: `/store/${store}`,
      data: data[store],
    });
  });
  products.forEach((product) => {
    if (!product.category) {
      return;
    }
    data[product.category] && data[product.category].push(product);
    data[product.store] && data[product.store].push(product);
  });
  return routes;
};

export { getRoutes };
