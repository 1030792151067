import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyATqRSMqJYVy9swunCkEYsRQAE0RF5Uiek',
  authDomain: 'blackfriday-us-e3ed3.firebaseapp.com',
  databaseURL: 'https://blackfriday-us-e3ed3.firebaseio.com',
  projectId: 'blackfriday-us-e3ed3',
  storageBucket: 'blackfriday-us-e3ed3.appspot.com',
  messagingSenderId: '196812969514',
};

firebase.initializeApp(config);

const getData = () => new Promise((resolve) => {
  const database = firebase.database().ref('/');
  database.on('value', snapshot => {
    resolve(snapshot.val());
  });
});

export {
  firebase as default,
  getData,
};
